import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroVideo from "../components/HeroVideo"
import HeroIndustry from "../components/HeroIndustry"
import TrustedBy from "../components/TrustedBy"
import Socials from "../components/Socials"
import play from "../images/play.svg"

const Index = ({ data, location }) => {
  const content = data.allContentfulIndustryPage.nodes
  const logos = data.contentfulTrustedByLogos.logos

  return (
    <Layout location={location}>
      <Seo title="Welcome" />

      <HeroVideo />

      <div
        style={{
          height: "104px",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <img src={play} alt="" height="40px" />
      </div>

      <HeroIndustry
        title="Healthcare"
        image={content[2].image.gatsbyImageData}
        linkTo="/healthcare"
        summary={content[2].heroSummary.heroSummary}
        orderStyle="even"
      />
      <HeroIndustry
        title="Retail"
        image={content[3].image.gatsbyImageData}
        linkTo="/retail"
        summary={content[3].heroSummary.heroSummary}
        orderStyle="odd"
      />
      <HeroIndustry
        title="Government"
        image={content[1].image.gatsbyImageData}
        linkTo="/government"
        summary={content[1].heroSummary.heroSummary}
        orderStyle="even"
      />
      <HeroIndustry
        title="Education"
        image={content[0].image.gatsbyImageData}
        linkTo="/education"
        summary={content[0].heroSummary.heroSummary}
        orderStyle="odd"
      />
      <HeroIndustry
        title="Technology"
        image={content[4].image.gatsbyImageData}
        linkTo="/technology"
        summary={content[4].heroSummary.heroSummary}
        orderStyle="even"
      />

      <div
        style={{
          height: "72px",
          display: "flex",
          paddingBottom: "16px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={play} alt="" height="40px" />
      </div>

      <Socials />

      <TrustedBy logos={logos} />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query HeroIndustryContent {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulIndustryPage(sort: { fields: [title], order: ASC }) {
      nodes {
        heroSummary {
          heroSummary
        }
        image {
          gatsbyImageData
        }
      }
    }
    contentfulTrustedByLogos {
      logos {
        title
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`
