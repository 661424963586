import React from "react"
import insta from "../../images/social/instagram.svg"
import linkedin from "../../images/social/linkedin.svg"
import * as styles from "./Socials.module.css"

const Socials = () => {
  return (
    <div className={styles.socials}>
      <h2 className={styles.header}>Social Media</h2>
      <div>
        <a href="https://www.instagram.com/medea.inc/">
          <img src={insta} alt="Instagram" className={styles.logo} />
        </a>
        <a href="https://www.linkedin.com/company/medea-vodka/">
          <img src={linkedin} alt="LinkedIn" className={styles.logo} />
        </a>
      </div>
    </div>
  )
}

export default Socials
