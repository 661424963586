import React, {useState} from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { trusted_header, trusted_carousel, trusted_logos, caret_left, caret_right } from "./TrustedBy.module.css"

import caret from "../../icons/Caret.svg"


const TrustedBy = ({ logos }) => {
    const logoImages = logos.map( logo => {
        return(
            <GatsbyImage
                image={logo.gatsbyImageData}
                alt={logo.title}
            />
        )
    })

    const [firstIndex, setFirstIndex] = useState(0);

    let moveRight = () => {
        if (firstIndex < (logos.length - 6) ) {
            setFirstIndex(firstIndex + 1)
        }
    }

    let moveLeft = () => {
        if (firstIndex > 0) {
            setFirstIndex(firstIndex - 1)
        }
    }

    return (
        <div className="section-wrapper">
            <h2 className={trusted_header}>Trusted By</h2>
            <div className={trusted_carousel}>
                <img src={caret} alt="Left arrow" className={caret_left} onClick={moveLeft}/>

                <div className={trusted_logos} id="logo-bar">
                    {logoImages[firstIndex]}
                    {logoImages[firstIndex + 1]}
                    {logoImages[firstIndex + 2]}
                    {logoImages[firstIndex + 3]}
                    {logoImages[firstIndex + 4]}
                    {logoImages[firstIndex + 5]}
                </div>
                
                <img src={caret} alt="Right arrow" className={caret_right} onClick={moveRight}/>
            </div>
        </div>
    )
}

export default TrustedBy