// extracted by mini-css-extract-plugin
export var heroindustry_button = "HeroIndustry-module--heroindustry_button--d5458";
export var heroindustry_button_caret = "HeroIndustry-module--heroindustry_button_caret--6da3e";
export var heroindustry_content_even = "HeroIndustry-module--heroindustry_content_even--09f59";
export var heroindustry_content_odd = "HeroIndustry-module--heroindustry_content_odd--2988f";
export var heroindustry_even = "HeroIndustry-module--heroindustry_even--b021a";
export var heroindustry_image_even = "HeroIndustry-module--heroindustry_image_even--b2f69";
export var heroindustry_image_odd = "HeroIndustry-module--heroindustry_image_odd--78d4e";
export var heroindustry_link = "HeroIndustry-module--heroindustry_link--a18a6";
export var heroindustry_odd = "HeroIndustry-module--heroindustry_odd--fbb02";
export var heroindustry_summary = "HeroIndustry-module--heroindustry_summary--a4eca";
export var heroindustry_text_even = "HeroIndustry-module--heroindustry_text_even--479f0";
export var heroindustry_text_odd = "HeroIndustry-module--heroindustry_text_odd--e05a3";
export var heroindustry_title = "HeroIndustry-module--heroindustry_title--2b4a2";