import { Link } from 'gatsby'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { heroindustry_odd,
    heroindustry_content_odd,
    heroindustry_image_odd,
    heroindustry_text_odd,
    heroindustry_even,
    heroindustry_content_even,
    heroindustry_image_even,
    heroindustry_text_even,
    heroindustry_title,
    heroindustry_summary,
    heroindustry_link,
    heroindustry_button,
    heroindustry_button_caret
} from "./HeroIndustry.module.css"
import caret from '../../icons/CaretRight.svg'

const HeroIndustry = ( props ) => {

    let heroIndustry = heroindustry_odd
    let heroIndustryContent = heroindustry_content_odd
    let heroIndustryImage = heroindustry_image_odd
    let heroIndustryText = heroindustry_text_odd

    if (props.orderStyle === "even") {
        heroIndustry = heroindustry_even
        heroIndustryContent = heroindustry_content_even
        heroIndustryImage = heroindustry_image_even
        heroIndustryText = heroindustry_text_even
    }

    return (
        <div className={heroIndustry}>
            <div className={heroIndustryContent}>

                <GatsbyImage
                    image={props.image}
                    alt={props.title}
                    className={heroIndustryImage}
                />

                <div className={heroIndustryText}>
                    <h2 className={heroindustry_title}>{props.title}</h2>

                    <p className={heroindustry_summary}>{props.summary}</p>

                    <Link to={props.linkTo} className={heroindustry_link} >
                        <button className={heroindustry_button}>
                            Learn more
                            <img src={caret} alt="" className={heroindustry_button_caret}/>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default HeroIndustry
