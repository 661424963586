import React from 'react'

import { hero_main, hero_text, hero_video } from "./HeroVideo.module.css"

const HeroVideo = () => {
    return (
        <div className={hero_main}>
            <video autoPlay muted loop playsInline className={hero_video}>
                <source src='/hero-video.mp4' type="video/mp4" />
            </video>

            <h1 className={hero_text}>Providing businesses & governments with the products they need.</h1>
        </div>
    )
}

export default HeroVideo
